body {
  height: 100vh;
  overflow-x: hidden;
  color: #3c484e;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.5rem;
  line-height: 1.6em;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0;
  text-rendering: optimizeLegibility;
  background: azure;
}

#root {
  height: 100vh;
}

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-items: center;
  height: 100%;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/*
* extend default classes
*/
.Main {
  width: 90%;
  height: 100%;
  overflow-y: scroll;
}

.Menu {
  background-image: url('../images/pexels-pixabay-531880.jpg');
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 20%;
  margin: 5px;
  border-radius: 20px;
}

.Content {
  /*background-image: url('../images/pexels-roberto-shumski-1903702.jpg'); */
  display: flex;
  align-items: center;
  justify-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
  margin: 5px;
  border-radius: 20px; 
  background-color: rgb(241, 239, 231);
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
