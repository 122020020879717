.footer {
  width: 90%;
  height: 20%;
  margin: 5px;
}

.page-footer.g-light.text-center.text-white {
  width: 90%;
  height: 20%;
  border-radius: 20px;
  border-color: bisque;
  margin: 5px;
}