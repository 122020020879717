.blogStory {
    width: 100%;
    height: 100%;
    display:block;
    align-items: center;
}

.blogImage {
    display: flex;
    width: 100%;
    height: 80%;
    justify-content: center;
}

.blogToOverview {
    display: flex;
    justify-content: center;
    align-items: center;
    float: inline-start;
    
    width: 15%;
    height: 10%;
    font-family: 'Courier New', Courier, monospace;
    font-size: medium;
    font-weight: bold;
    font-style: normal;
    background-color: gray;
    border-radius: 5px;
    border-color: black;
}