.BlogIconOverview {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.BlogIconItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30%;
    height: 65%;
    border: 2px;
    border-radius: 5px;
    border-color: bisque;
}

.IconImage {
    display: flex;
    justify-content: center;
    padding-top: 15px;
    width: 100%;
    height: 30vh;
}